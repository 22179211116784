<template>
    <div>
        <div class="narnoo-my-2">
            <div class="narnoo-bg-yellow-50 narnoo-border-l-4 narnoo-border-yellow-400 narnoo-p-4" v-if="inputDates.dateList[0]==null">
                <div class="narnoo-flex">
                    <div class="narnoo-flex-shrink-0">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#fc3" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M12 1l-12 22h24l-12-22zm-1 8h2v7h-2v-7zm1 11.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"></path>
                        </svg>
                    </div> 
                    <div class="ml-3">
                        <p class="narnoo-text-sm narnoo-text-yellow-700">
                          Bookings are not available for the following dates. Please select different dates. 
                        </p>
                    </div>
                </div>
            </div>
  
            <div class="narnoo-p-1 narnoo-w-full sm:narnoo-w-3/4 lg:narnoo-w-1/2 sm:narnoo-mx-auto">
              <div class="narnoo-flex narnoo-flex-wrap narnoo-gap-1" v-if="!showPickTimeOptions">
                  <div class="" v-for="(range,dateRangeIndex) in dateRange" :key="dateRangeIndex">
                      <button type="button" class="focus:narnoo-outline-none narnoo-h-auto md:narnoo-w-24 lg:narnoo-w-18"
                      v-bind:class="[range !=null && range.availability>0 ? 'bg-btn-green' : 'bg-btn-red']"
                      @click="range!=null  && range.availability>0  ? showAvailablity(range) : null"
                      >
                          {{ range.weekday }}<br> {{ range.date }} <br>{{ range.monthName }}
                      </button>
                  </div>
              </div>
    
            <div v-if="showPickTimeOptions">
                <a class="narnoo-flex narnoo-text-blue-700 narnoo-font-bold narnoo-py-3 focus:narnoo-text-blue-500" href="javascript:void(0)" @click="returnToDaysView()">
                    <svg xmlns="http://www.w3.org/2000/svg" class="narnoo-h-6 narnoo-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
                    </svg>
                    <span>Go back to Date view</span>
                </a>
  
                <div class="narnoo-flex" v-for="(timeData,timeIndex) in timeSlots" :key="timeIndex">
                    <div>
                        <button type="button" class="narnoo-inline-flex narnoo-items-center narnoo-mr-1 narnoo-mb-1 bg-btn-black focus:narnoo-outline-none ">
                            {{ timeData.time ? timeData.time : timeData == '00:00:00' ? '' : timeData }}
                        </button>
                    </div>
                    <div v-bind:class="showPickTimeOptions ? 'narnoo-w-full sm:narnoo-w-16 md:narnoo-w-24 lg:narnoo-w-18' : ''" >
                        <div v-for="(dateData,dateIndex) in parseCalendarData(timeIndex)" :key="dateIndex">
                            <button type="button" class="narnoo-inline-flex narnoo-flex-col narnoo-items-center narnoo-mr-1 narnoo-mb-1 focus:narnoo-outline-none "
                            v-bind:class="[dateData !=null && dateData.availability>0 ? showPickTimeOptions ?  'bg-btn-green-full-width' : 'bg-btn-green' : showPickTimeOptions ?  'bg-btn-red-full-width' : 'bg-btn-red']"
                            @click="dateData!=null  && dateData.availability>0  ? getBookingDetails(timeData.time ? timeData.time : timeData,dateData.supplierId,dateData.productId,dateData.bookingCode,dateData.bookingDateDisplay,dateData.bookingDate,timeIndex,dateIndex,productIndex,dateData.price,dateData.availability) : null"
                            >
                                <div class="narnoo-leading-tight narnoo-hidden narnoo-flex narnoo-flex-col narnoo-items-center" :id="`bookingBtnLoader-${timeIndex}-${dateIndex}-${productIndex}`">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="narnoo-w-6 narnoo-h-6 narnoo-animate-spin">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                  </svg>
                                  
                                  <span class="narnoo-text-xs">Loading</span>
                                </div>
                                <div class="narnoo-leading-tight narnoo-block" :id="`bookingBtnContent-${timeIndex}-${dateIndex}-${productIndex}`">
                                    {{ dateData !=null && dateData.availability>0 ? 'BOOK' : 'X'}} 
                                    <span class='narnoo-text-xs'>{{ dateData !=null && dateData.availability>0 ?'(' + dateData.availability+')' : ''}}</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
</template>
  
  <script>
  
  const { decrypt } = require('../helpers/crypto');
  import axios from 'axios';

  export default {
    props: ['tourOption','inputDates','productIndex','navigateSteps','bookingCutoff'],
      mounted() {
        this.parseInputDates(this.inputDates)
      },
      data(){
          return{
              calendarData:[],
              timeSlots:null,
              dateRange:null,
              showPrevButton:true,
              startDate:null,
              endDate:null,
              bookLoading: false,
              showPickTimeOptions:false
          }
      },
      methods: {
          returnToDaysView() {
              this.showPickTimeOptions=false 
          },
  
          showAvailablity(dateRange){
              let calendarDates = this.inputDates
              let dateList= calendarDates.dateList[calendarDates.dateList.length - 1]
              
              if(dateList==null) return;

              if(calendarDates.productTimes!=null) {
                  this.generateCalendarWithTimeId(calendarDates.productTimes,dateRange,dateList,calendarDates.bookingCode,calendarDates.supplierId,calendarDates.productId)
              }
              else{
                  this.generateCalendarWithOutTimeId(dateRange,dateList,calendarDates.bookingCode,calendarDates.supplierId,calendarDates.productId)
              }
  
              this.showPickTimeOptions=true
  
          },
  
        /**
         * Function that renders the calendar according to selected time id
         * params :
         * - productTimes - ProductTime array block coming from the availability/item.vue component.
         * - dateRange - array of dates to be displayed
         * - dateList - available dates for booking in calendar display
         */
  
        generateCalendarWithTimeId(productTimes,dateRange,dateList,bookingCode,supplierId,productId){
          let timeList= productTimes
  
          this.timeSlots=timeList
  
          this.calendarData=[]
  
          // loops tru each time available
  
          for (let t=0; t < timeList.length; t++) {
  
              let calendarRow = []
              let timeRow =  timeList[t]
  
              let pickDate = this.parseDateFormat(dateRange)
  
               // filtered by booking date and time  
               let filteredDateByTime = dateList.filter(data => data.bookingDateDisplay.split(" ")[1].indexOf(timeRow.time)>-1 &&  data.bookingDateDisplay.split(" ")[0] == pickDate)
               
               //check if there's available date otherwise save it as null in our calendar row
               // this is to make sure the calendar displays 6 days even if there's any missing dates.
               // this make the calendar consistent in layout too.
               
               if(filteredDateByTime.length>0){
                 filteredDateByTime.map(d=>{
                    d.timeString= timeRow.time
                    d.timeId = timeRow.id
                    d.supplierId = supplierId
                    d.bookingCode = bookingCode+":"+timeRow.id
                    d.productId = productId
                  })
                 calendarRow.push(filteredDateByTime[0])
               }
               else{
                 calendarRow.push(null)
               }
  
            //saves the created calendar row for ui display
            this.calendarData.push(calendarRow)
          }
  
        },
  
        /**
         * function that renders the calendar for non existing/null Product times
         * params
         * - dateRange - array of dates to be displayed
         * - dateList - available dates for booking in calendar display
         */
  
        generateCalendarWithOutTimeId(dateRange,dateList,bookingCode,supplierId,productId){
          // since we dont have any product time id, we generated the calendar row/time slots from each booking date display
          // saves the trailing time string in the bookingDateDisplay as timeSlots
            let timeSlots = []
            for(let x = 0; x < dateList.length; x++) {
              let bookingDate = dateList[x].bookingDateDisplay
              if(!timeSlots.includes(bookingDate.split(" ")[1])) {
                timeSlots.push(bookingDate.split(" ")[1])
              }
            }
  
            this.calendarData=[]
  
            this.timeSlots=timeSlots
  
            // loops tru all timeslots 
  
            for (let t=0; t < timeSlots.length; t++) {
  
              let calendarRow = []
  
              let pickDate = this.parseDateFormat(dateRange)
              // check if there's available date otherwise save it as null in our calendar row
              // this is to make sure the calendar displays 6 days even if there's any missing dates.
              // this make the calendar consistent in layout too.
  
              let filteredDateByTime = dateList.filter(data => data.bookingDateDisplay.split(" ")[1].indexOf(timeSlots[t])>-1 &&  data.bookingDateDisplay.split(" ")[0] == pickDate)
      
  
              if(filteredDateByTime.length>0){
              filteredDateByTime.map(d=>{
                  d.timeString= timeSlots[t]
                  d.timeId = null
                  d.supplierId = supplierId
                  d.bookingCode = bookingCode
                  d.productId = productId
                  })
              calendarRow.push(filteredDateByTime[0])
              }
              else{
              calendarRow.push(null)
              }
  
              this.calendarData.push(calendarRow)
            }
            
        },
  
        /**
         * function that runs initially when calendar component is loaded.
         * this function manages 3 areas in the calendar display ( startDate, end Date, and availablity of productTimes)
         * - params:
         * - calendarDates - the availability array result from the item component
         */
  
         parseInputDates(calendarDates){

          // converts the dates to standard iso format

          this.startDate = calendarDates.startDate.split("-").reverse().join("-")
          this.endDate = calendarDates.endDate.split("-").reverse().join("-")

          // genearate the array of dates that acts as columns of the calendar
          var getDaysArray = function(start, end) {
              for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
                  arr.push(new Date(dt))
              }
              return arr
          };

          let dateRange = getDaysArray(new Date(calendarDates.startDate.split("-").reverse().join("-")),new Date(calendarDates.endDate.split("-").reverse().join("-")))

          let dateList= calendarDates.dateList[calendarDates.dateList.length - 1]

          // add some date details to create the dates heading for each date
          dateRange.map(d=>{
            let selectedDate = d.toISOString().split("T")[0]

              let currentDate = this.parseDateFormat(d)

              let filteredDateByTime = dateList.filter(data => data.bookingDateDisplay.split(" ")[0] == currentDate)

              if (filteredDateByTime.length>0) {
                d.availability = filteredDateByTime[0].availability
              }

              d.weekday=new Date(selectedDate).toLocaleString('default', { weekday: 'long' })
              d.monthName=new Date(selectedDate).toLocaleString('default', { month: 'long' })
              d.longDate = new Date(selectedDate)
              d.date = selectedDate.split("-")[2]
          })

          this.dateRange = dateRange

          },

  
        parseDateFormat(date) {
          return date.toISOString().split("T")[0].split("-").reverse().join("-")
        },
  
        /**
         * function that returns only the dates from an specific timeIndex.
         * this generate the actual bookable buttons in the ui.
         */
  
         parseCalendarData(timeIndex) {

          return this.calendarData[timeIndex]
        },

        
        getDetails(dateData, timeIndex,dateIndex,productIndex) {
          // shows the loader ui
          document.getElementById(`bookingBtnLoader-${timeIndex}-${dateIndex}-${productIndex}`).classList.remove("narnoo-hidden")
          document.getElementById(`bookingBtnContent-${timeIndex}-${dateIndex}-${productIndex}`).classList.remove("narnoo-block")
          document.getElementById(`bookingBtnContent-${timeIndex}-${dateIndex}-${productIndex}`).classList.add("narnoo-hidden")
          this.$emit('getBookingDetails', dateData)
          this.$emit('getBookingtimeIndex', timeIndex)
        },
        isAllowedByCutOff(bookingDate){
          bookingDate = bookingDate.split(" ")

          if(bookingDate.length>1){
            bookingDate = `${bookingDate[0].split("-").reverse().join("-")} ${bookingDate[1]}`
          }

          if (this.bookingCutoff !== null && this.bookingCutoff !== undefined && this.bookingCutoff !== '' ) {

            let userDate = new Date()
            let attemptedBookingDate = new Date(bookingDate)
            let dateDiff = (attemptedBookingDate.valueOf() - userDate.valueOf() ) / 36e5;
            
            // make sure datedifference is not a negative one. negative date difference means user tries to book but his current time already over the booking date time.
            if(dateDiff < 0 ) {
              return false
            }
            else if (parseFloat(this.bookingCutoff)>= dateDiff) {
              // if bookingcutoff hours is greater then that means we will not allow the booking since date difference is within the bookingcutoff
              return false
            }
            else{
              // we return true if date diff is outside bookingcutoff 
              return true
            }
          }
          else{
            // if user did not configure booking cutoff we automatically set the booking as allowed
            return true
          }
        },
  
        async getBookingDetails(selectedTime,supplierId,productId,bookingCode,bookingDateDisplay,bookingDate,timeIndex,dateIndex,productIndex,guestOptions,availability) {
            let widgetInitData = JSON.parse(decrypt(JSON.parse(localStorage.getItem("widgetInitData"))));
            let user = window.btoa(process.env.VUE_APP_USER_ID + ":" + process.env.VUE_APP_ACCESS_TOKEN);
            let searchParams  = {
            headers: { 'Authorization': "Basic " + user },
            params: {
                bookingCode: bookingCode,
                bookingDate : bookingDateDisplay.split(' ')[0],
            }
            }

            // prevent any other calls while there is a booking call currently on process
            if(this.bookLoading==true) return;

            this.bookLoading=true

            axios.get(process.env.VUE_APP_API_URL+"/booking/data_widget/"+widgetInitData.businessId+"/"+widgetInitData.businessType+"/"+widgetInitData.operatorId+"/"+widgetInitData.bookingId,searchParams)
            .then(data =>this.navigateSteps(2,{
                supplierId:supplierId,
                productId:productId,
                bookingCode:bookingCode,
                bookingDate: bookingDate,
                bookingDateDisplay: bookingDateDisplay,
                timeId: this.timeSlots[timeIndex].id,
                productTime: selectedTime,
                tourOption : this.tourOption,
                details:data,
                guestOptions:guestOptions
            }) )
            .catch(err=>console.log("error getting booking data " +err))

            // shows the loader ui
            document.getElementById(`bookingBtnLoader-${timeIndex}-${dateIndex}-${productIndex}`).classList.remove("narnoo-hidden")
            document.getElementById(`bookingBtnContent-${timeIndex}-${dateIndex}-${productIndex}`).classList.remove("narnoo-block")
            document.getElementById(`bookingBtnContent-${timeIndex}-${dateIndex}-${productIndex}`).classList.add("narnoo-hidden")
  
        },
  
      }
  
  }
  </script>
  
  <style>
  .blocks{
    display: none !important;
  }
  .kalendar-wrapper.gstyle .sticky-top .days{
    padding-left: 0% !important;
  }
  .kalendar-wrapper{
    margin-left: 14% !important;
  }
  
  .bg-btn-black{
    background: #6B7280;
    font-size: 13px;
    color: white;
    width: 80px;
    height: 58px;
    white-space: nowrap;
    justify-content: center;
  }
  .bg-btn-green{
    background: #059669;
      font-size: 13px;
    color: white;
    width: 80px;
    height: 58px;
    white-space: nowrap;
    justify-content: center;
  }
  .bg-btn-green-full-width{
    background: #059669;
      font-size: 13px;
    color: white;
    width: 100%;
    height: 58px;
    white-space: nowrap;
    justify-content: center;
  }
  .bg-btn-red{
    width: 80px;
    height: 58px;
    background: #E11D48;
    font-size: 13px;
    color: white;
    white-space: nowrap;
    justify-content: center;
  }
  .bg-btn-red-full-width{
    width: 100%;
    height: 58px;
    background: #E11D48;
    font-size: 13px;
    color: white;
    white-space: nowrap;
    justify-content: center;
  }
  .day-indicator{
    background-color:#3B82F6;
    border-right: solid 2px var(--table-cell-border-color) !important;
    color: white !important;
  }
   .today .letters-date{
    color: white !important;
  }
  
  .mg-le-2{
    margin-left: -2px;
  }
  
  .mg-le-3{
    margin-left: -3px;
  }
  .mg-le-5{
    margin-left: 5.4rem;
  }
  .bg-btn-white{
    line-height: 1;
    width: 80px;
    height: 58px;
    background: #ffffff;
    font-size: 13px;
    color: white;
    white-space: nowrap;
    justify-content: center;
  }
  .bg-btn-blue{
    line-height: 1;
    width: 80px;
    height: 58px;
    background: #3B82F6;
    font-size: 13px;
    color: white;
    white-space: nowrap;
    justify-content: center;
  }
  </style>