<template>
  <div id="app">
    <!-- 
      Our error display.
      @todo - Conver to a component
      @todo - Dynamic description to the title and message
     -->
    <div v-if="propError" class="narnoo-bg-teal-100 narnoo-border-t-4 narnoo-border-red-500 narnoo-rounded-b narnoo-text-teal-900 narnoo-px-4 narnoo-py-3 narnoo-shadow-md" role="alert">
      <div class="narnoo-flex">
        <div class="narnoo-py-1">
          <svg class="narnoo-fill-current narnoo-h-6 narnoo-w-6 narnoo-text-red-500 narnoo-mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
          </svg>
        </div>
        <div>
         <p class="narnoo-font-bold">Instant Booking Unavailable.</p>
         <p class="narnoo-text-sm">Instant booking for this product is not available at this time. Please contact us and we can help you.</p>
        </div>
      </div>
    </div>
    
    <!--
      Production Component
    -->
    <Availability 
    v-bind:accessKey="access_key" 
    v-bind:operatorId="operator_id" 
    v-bind:bookingId="booking_id" 
    v-bind:bookingCode="booking_code" 
    v-bind:checkoutUrl="checkout_url"
    v-bind:displayDescription="display_description"
    v-bind:displayGuestPrice="display_guest_price"
    v-bind:displayCartButton="display_cart_button"
    v-bind:pickupId="pickup_id"
    v-bind:displayExtras="display_extras"
    v-bind:bookingCutoff="booking_cutoff"
    v-bind:rangeDateStatus="range_date_status"
    v-bind:reservationId="reservation_id"
    v-bind:customTheme="custom_theme"
    v-bind:retailUi="retail_ui"
    v-if=" !propError && productionMode=='true'  &&  loadAvailabilityComponent"
    />
    
  </div>
</template>

<script>
/**
 * The entry point to the application
 * 
 * Inspired from:
 * https://www.getyourguide.co.uk/green-island-l8250/full-day-green-island-reef-cruise-t29795/
 * 
 * 
 * @description - Build out a booking widget for our point of sale application
 * 
 * @comment - We never make a live booking to a supplier in testing.. NEVER!
 * 
 * 
 * @example - access_key = "123131332423"
 * @example - operator_id = "1502"
 * @example - booking_id = "1836"
 * 
 * 
 */
import Availability from './components/Availability.vue'

export default {
  name: 'App',
  /**
   * These are the main props which we allow the user to control when embedding the widget.
   * @comment - All props are in "snake_case"
   * @param 'access_key' - Their Narnoo access key which can be found in the developer console within app.narnoo.com
   * @param 'operator_id' - The Narnoo operator id which is the ID of the operator which they want to connect to.
   * @param 'booking_id' - The Narnoo booking id which is the ID of the operators' product which they want to sell.
   * @param 'checkout_url' - The absolute URL to the checkout page which hosts the cart widget.
   * @param 'booking_code' - A direct booking link ( not really in production ).
   * @param 'pickup_id' - A pickup id which can automatically set the pick up location for the product.
   * @param 'display_description' - Do we show the product description in the widget ( true (default) || false )
   * @param 'display_cart_button' - Do we display the cart button - I need to check this ( true (default) || false )
   * @param 'display_extras' - Do we display any product extras that might come over in the API ( true (default) || false )
   * @param 'booking_cutoff' - The number of days we want to cut off a booking. This overrides any API settings. 
   * @example - if this was set to 2 then we automatically set availablity to 0 for two days into the future.
   * @param 'range_date_status' - Do we display a date range UI instead of just a single date selector ( false (default) || true )
   * @param 'reservation_id' - Is there a reservation Id set. This is for inhouse charges (TBA)
   * @param 'custom_theme' - Is there a custom theme set - Pretty messy code here. Will need to be revised.
   * @param 'retail_ui' - Is the widget displaying a similar theme to our Retail application ( true (default) || false )
   * @param 'display_guest_price' - Do we display the guest pricing ( false (default) || true )
   */
  props: [
    'access_key',
    'operator_id',
    'booking_id',
    'checkout_url',
    'booking_code',
    'pickup_id',
    'display_description',
    'display_cart_button',
    'display_extras',
    'booking_cutoff',
    'range_date_status',
    'reservation_id',
    'custom_theme',
    'retail_ui',
    'display_guest_price'
  ],
  components: {
    Availability
  },
  data() {
    /**
     * Basic returns
     * @param propError - show initate errors
     * @param ProductionMode - Environmental data to set if in production or local testing
     * @param widgetVersion - Versioning control
     * @param loadAvailabilityComponent - Loading and versioning controls
     * @param theme - Basic static theme set up.
     * @param retail_ui_default - Default value for retail_ui
     */
      return {
        propError:false,
        productionMode:process.env.VUE_APP_PRODUCTION,
        widgetVersion:"1.8.12",
        widgetMode:"production",
        loadAvailabilityComponent:false,
        theme: (this.productionMode=='true' ? {primary: this.primary_color , secondary: this.secondary_color} : {primary: '#108fcf', secondary:'#37A1D4'}),
        // Initialize retail_ui default value
        retail_ui_default: true
      }
  },
  mounted() {
    /**
     * @description - We use a lot of localstorage to talk between widgets. Because of this we need to clear things when version numbers don't match.
     *                If we don't do this we get inconsistant behaviours due to localstorage data.
     */
    if (localStorage.getItem("widgetVersion")!==null && localStorage.getItem("widgetVersion")=== this.widgetVersion) {
      this.loadAvailabilityComponent=true
    }
    else{
      this.clearStorages()
      localStorage.setItem("widgetVersion",this.widgetVersion)
      this.loadAvailabilityComponent=true
    }
    /**
     * @description Check if retail_ui is provided as a prop; otherwise, default to true
     */
    if (this.retail_ui === undefined) {
      this.retail_ui = this.retail_ui_default;
    }
    /**
     * @description Notify the console window of the version number.
     */
    console.log(`Standard Widget ${this.widgetMode} - Version ${this.widgetVersion}`)
    /**
     * @description Look for the required props and if they don't exist show the warning.
     */
    if(this.productionMode=='true') {
      if(this.access_key==null || this.operator_id==null || this.booking_id==null || this.checkout_url==null) {
        this.propError=true
      }
    }
  },
  methods : {
    /**
     * @description - We must clear all localstorage values across both the availability widget as well as the cart widget when we have a version change.
     */
    clearStorages(){
      localStorage.removeItem("promotionalCode");
      localStorage.removeItem("cartPromoDiscount");
      localStorage.removeItem("cartTotalPrice");
      localStorage.removeItem("cartTotalPriceWithPromo");
      localStorage.removeItem("cart");
      localStorage.removeItem("widgetInitData");
      localStorage.removeItem("bookingPayload");
      localStorage.removeItem("bookingFee");
      localStorage.removeItem("cartData");
      localStorage.removeItem("accessKey");
      localStorage.removeItem("primaryPayment");
      localStorage.removeItem("anonymousId");
      localStorage.removeItem("mainUserId");
      localStorage.removeItem("currencies");
      localStorage.removeItem("currency");
    }
  }
}
</script>
<style>
</style>
